import React from 'react';
import { graphql } from 'gatsby';

import Page, { PageType } from '@/components/Page';
import Content, {
  ContentBlocksProps,
  createContentItems,
} from '@/components/Content';
type Props = ContentBlocksProps & PageType;

const ContentPageTemplate: React.FC<Props> = (props) => {
  const backgroundColor: string = props.data.contentfulPage.headerBackground;

  const { content } = props.data.contentfulPage.pageType;
  const contentItems = createContentItems(content, props);

  return (
    <Page {...props}>
      <Content
        locale={props.pageContext.locale}
        pageColor={backgroundColor}
        items={contentItems}
        topContent={true}
      />
    </Page>
  );
};

export default ContentPageTemplate;

export const pageQuery = graphql`
  query ContentPageQuery(
    $contentfulId: String!
    $locale: String
    $articles: [String]
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      seoMetadata {
        ...SeoMetaDataFragment
      }
      headerBackground
      pageType {
        ... on ContentfulContentPageType {
          content {
            ...ContentFragment
          }
        }
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    relatedArticles: allContentfulArticle(
      filter: { contentful_id: { in: $articles }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleBlockFragment
      }
    }
    contentfulBlogPageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulSupportHomepageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
  }
`;
